<template>
  <div class="row">
    <div v-if="popupParam.multiple" class="col-lg-12 col-md-12 col-sm-12">
      <c-table
        ref="table"
        title="설비 목록"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :editable="editable"
        rowKey="equipmentCd"
        @rowClick="rowClick"
        @callbackProxy="callbackProxy"
        :selection="popupParam.noPlan === 'Y' ? 'multiple' : 'none'"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && popupParam.noPlan === 'Y'" label="무계획결과 저장" icon="save" />
            <c-btn v-if="editable && popupParam.noPlan === 'Y'" label="LBLADD" icon="add" />
            <c-btn v-if="editable && popupParam.noPlan === 'Y'" label="LBLREMOVE" icon="remove" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="설비별 결과상세">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="editable && popupParam.noPlan === 'N'" :editable="editable" label="설비별 점검결과 저장" icon="save" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-card class="cardClassDetailForm" title="설비별 결과 기본정보"  bgClass="">
              <template slot="card-detail">
                <div class="col-12">
                  <c-label-text title="사업장/점검부서" :value="result.plantName +'/' + result.deptNm"></c-label-text>
                </div>
                <div class="col-12">
                  <c-label-text title="설비유형/설비코드/설비명" :value="result.equipmentType + '/' + result.equipmentCd + '/' + result.equipmentNm"></c-label-text>
                </div>
                <div class="col-12">
                  <c-label-text title="점검유형" :value="result.safFacilityNm"></c-label-text>
                </div>
                <div class="col-12">
                  <c-label-text title="점검자" :value="result.chkUserNm"></c-label-text>
                </div>
                <div class="col-12">
                  <c-label-text title="점검예정일" :value="result.chkSchYmd"></c-label-text>
                </div>
                  <div class="col-12">
                </div>
                  <div class="col-12">
                  <c-datepicker
                    label="점검완료일"
                    name="chkSchCompleYmd"
                    v-model="result.chkSchCompleYmd">
                  </c-datepicker>
                </div>
                <div class="col-12">
                  <c-select
                    :comboItems="chkResultItems"
                    :editable="editable"
                    type="edit"
                    itemText="codeName"
                    itemValue="code"
                    name="chkResult"
                    label="점검결과"
                    v-model="result.chkResult"
                  ></c-select>
                </div>
                <div class="col-12">
                  <c-select
                    :comboItems="chkRsltYnItems"
                    :editable="editable"
                    type="edit"
                    itemText="codeName"
                    itemValue="code"
                    name="chkRsltYn"
                    label="점검완료여부"
                    v-model="result.chkRsltYn"
                  ></c-select>
                </div>
                <div class="col-12">
                  <c-textarea
                    :editable="editable"
                    label="점검결과요약"
                    name="chkRsltSmry"
                    v-model="result.chkRsltSmry">
                  </c-textarea>
                </div>
              </template>
            </c-card>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
            <div class="col-12">
              <c-table
                ref="table"
                title="설비별 점검항목"
                :columns="grid2.columns"
                :gridHeight="grid2.height"
                :data="grid2.data"
                :filtering="false"
                :columnSetting="false"
                :usePaging="false"
                :hideBottom="true"
                noDataLabel="설비에 대한 점검항목이 없습니다."
                :editable="editable&&!popupParam.disabled"
                rowKey="checkItemSeq"
                selection="multiple"
              >
                <template slot="table-button">
                  <q-btn-group outline >
                    <c-btn v-if="editable" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addItem" />
                    <c-btn v-if="editable && grid2.data.length > 0" label="LBLREMOVE" :showLoading="false" icon="remove" @btnClicked="removeItem" />
                  </q-btn-group>
                </template>
              </c-table>
            </div>
          </div>
        </template>
      </c-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'equipment-result',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        title: '',
        noPlan: 'N',
        multiple: false,
      }),
    },
  },
  data() {
    return {
      editable: true,
      result: {
        plantName: '사업장1',
        deptNm: '안전환경팀',
        equipmentType: '집진설비',
        equipmentCd: 'F3-G938',
        equipmentNm: '집진설비 설비명',
        chkResult: '1',
        chkRsltYn: 'Y',
        chkSchYmd: '2021-07-24',
        chkRsltSmry: '점검결과요약',
        chkSchCompleYmd: '2021-07-25',
        safFacilityNm: '법정(정기)점검',
        chkUserNm: 'manager'
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true
          },
          {
            name: 'deptNm',
            field: 'deptNm',
            label: '점검부서',
            align: 'center',
            sortable: true
          },
          {
            name: 'equipmentType',
            field: 'equipmentType',
            label: '설비유형',
            align: 'center',
            sortable: true
          },
          {
            name: 'equipmentCd',
            field: 'equipmentCd',
            label: '설비코드',
            align: 'center',
            sortable: true
          },
          {
            name: 'safFacilityCd',
            field: 'safFacilityCd',
            label: '점검유형',
            align: 'center',
            style: 'width:100px',
            type: 'select',
            comboItems: [
              { code: '1', codeName: '자체(일상)정비' },
              { code: '2', codeName: '법정(정기)정비' },
            ],
            sortable: false,
          },
          {
            name: 'chkUserNm',
            field: 'chkUserNm',
            label: '점검예정자',
            align: 'center',
            style: 'width:120px',
            type: 'user',
            userId: 'chkUserId',
            sortable: false,
          },
          {
            name: 'chkSchYmd',
            field: 'chkSchYmd',
            label: '점검예정일',
            align: 'center',
            style: 'width:200px',
            type: 'date',
            sortable: true,
            disableTarget: 'chngRsltYn',
            disableCon: 'O',
          },
          {
            name: 'remark',
            field: 'remark',
            label: 'LBLREMARK',
            align: 'left',
            style: 'width:200px',
            type: 'textarea',
            sortable: false,
          },
        ],
        data: [],
        height: '300px'
      },
      grid2: {
        columns: [
          {
            name: 'checkItemNm',
            field: 'checkItemNm',
            label: '점검항목',
            align: 'center',
            sortable: true
          },
          {
            name: 'checkResult',
            field: 'checkResult',
            label: '점검결과',
            align: 'center',
            type: 'text',
            sortable: true
          },
          {
            name: 'inspMethod',
            field: 'inspMethod',
            label: '검사방법',
            align: 'center',
            type: 'text',
            sortable: true
          },
          {
            name: 'criteria',
            field: 'criteria',
            label: '판정기준',
            align: 'center',
            type: 'text',
            sortable: true
          },
          {
            name: 'safetyInfo',
            field: 'safetyInfo',
            label: '점검시 안전사항',
            align: 'center',
            type: 'textarea',
            sortable: true
          },
        ],
        data: [],
        height: '650px'
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      chkResultItems: [],
      chkRsltYnItems: [],
      title: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.title = this.popupParam.title;
      this.getList();
    },
    getList() {
      this.chkResultItems = [
        { code: '1', codeName: '적합' },
        { code: '2', codeName: '부적합' },
      ];
      this.chkRsltYnItems = [
        { code: 'Y', codeName: '완료' },
        { code: 'Y', codeName: '미완료' },
      ]
      this.grid.data = [
        { 
          plantName: '사업장1',
          deptNm: '안전환경팀',
          safFacilityNm: '자체(일상)점검',
          safFacilityCd: '1',
          equipmentType: '집진설비',
          equipmentNm: '집진설비 설비명',
          equipmentCd: 'F3-G938',
          chkSchYmd: '2021-07-24',
          chkUserId: '1',
          chngNum: 'MOC01',
          chkUserNm: '홍씨',
          remark: '집진설비에 대한 비고',
          chkRsltSmry: '점검결과요약'
        },
        { 
          plantName: '사업장1',
          deptNm: '개발팀',
          safFacilityNm: '법정(자체)점검',
          safFacilityCd: '2',
          equipmentType: '소방설비',
          equipmentNm: '소방설비 설비명',
          equipmentCd: 'F3-G940',
          chkSchYmd: '2021-07-24',
          chkUserId: '1',
          chngNum: 'MOC02',
          chkUserNm: '김씨',
          remark: '소방설비에 대한 비고',
          chkRsltSmry: '소방설비에 대한 점검결과요약'
        },
      ];
      this.grid2.data = [
        { 
          checkItemNm: '점검항목1',
          checkResult: '점검결과1',
          inspMethod: '검사방법1',
          criteria: '판정기준1',
          safetyInfo: '점검시 안전사항1',
        },
        { 
          checkItemNm: '점검항목2',
          checkResult: '점검결과2',
          inspMethod: '검사방법2',
          criteria: '판정기준2',
          safetyInfo: '점검시 안전사항2',
        },
      ];
    },
    rowClick(row) {
      this.result = this.$_.clone(row);
    },
    add() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = '설비 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.grid.data, {
            equipmentCd: _item.equipCd,
          });
          if (index === -1) {
            this.grid.data.splice(0, 0, {
              plantName: _item.plantName,
              deptNm: _item.deptNm,  
              chkUserNm: '', 
              chkUserId: '',
              safFacilityNm: null, 
              safFacilityCd: null, 
              equipmentType: _item.equipType, 
              equipmentCd: _item.equipCd, 
              equipmentNm: _item.equipNm,
              chkSchYmd: '', 
              remark: '', 
            })
          }
        })
      }
    },
    callbackProxy(data, props, col) {
      if (col.name === 'chngNum') {
        this.grid.data[props.rowIndex]['chngNum'] = data.chngNum;
      }
    },
    addItem() {
      this.grid2.data.splice(0, 0, {
        checkItemSeq: 0,
        checkItemNm: '', // 점검항목
        checkResult: '', // 점검결과
        inspMethod: '', // 검사방법
        criteria: '', // 판정기준
        safetyInfo: '', // 점검시 안전사항
      })
    },
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid2.data = this.$_.reject(this.grid2.data, item)
        })
      }
    },
  }
};
</script>
